import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./pages/home/Main";
import Admin from "./pages/admin/Admin";
import App from "./App";
// import Maintenance from "./pages/Maintenance/Maintenance";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//     {/* <Main /> */}
//     {/* <Maintenance /> */}
//     {/* <Admin/> */}
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <createBrowserRouter>
        <App />
      </createBrowserRouter>
  </React.StrictMode>
);
