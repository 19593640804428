import './App.css';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import React  from 'react';
import Main from './pages/home/Main';
import Login from './pages/login/login';
import Admin from './pages/admin/Admin';


function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
