import React  from 'react';
import "./Styles.css";
import logo from "../../img/logo_100.png";
import ply from "../../img/imgply.png";
import hp2 from "../../img/mu1.png"
import imgFooter from "../../img/FOOTER_WEB.png"
import Tilty from "react-tilty";
import img_wa from "../../img/assets/whatsapp.png"
import img_fb from "../../img/assets/facebook.png"
import img_tel from "../../img/assets/Telegram.png"
import img_shop from "../../img/assets/shopee.png"
import img_tik from "../../img/assets/Tiktok.png"
import img_ig from "../../img/assets/instagram.png"


function Main() {
  return (
    <div className="body" class="notranslate">
      <div className="container">
        <nav>
          <div className="logo-sadaya">
            <a className="linkdaftar" href="/">
              <div className="brand">
                <img className="logo-brand" src={logo} alt="Logo Sadaya" />
              </div>
            </a>
          </div>

          <div class="dropdown">
            <div className="ply">
              <div className="harga-btn">HARGA</div>
            </div>
            <div class="dropdown-content">
              <a href="http://sadaya.otoreport.com/harga.js.php?id=5415fe9244cc2b980f61d4eb4b7cb1190677e09a98b6850dc835324c016f7ae5f34a463eb2ffd0124bb3ce16e89cd242-12">
                Pulsa
              </a>
              <a href="http://sadaya.otoreport.com/harga.js.php?id=697e618307319d9ea1f177a30e2f1557f104886a0c5500d703ecd7c66c61fb089639f55ebc080528e5b7f17edd7b4d57-11">
                Kuota
              </a>
              <a href="http://sadaya.otoreport.com/harga.js.php?id=e52a7c3a738ea652ffea1a4010c41d49e6cc4a1caa41d05c381f4705f6b9c7b537f1290cc8051ac1327360eccbeb407f3b43-13">
                Token PLN
              </a>
              <a href="http://sadaya.otoreport.com/harga.js.php?id=8d3ac64a85fd824e3dd6c11ac9970016355e94ae0b581a6c8fad1b1e9d94c0de2bf37eb68c83e5f10b0cdaf62bbcb884-14">
                Topup Game
              </a>
            </div>
          </div>
        </nav>

        <div className="conten">
          <div className="kiri">
            <div className="brand-name-wrapper">
              <h1 className="brand-name1">mobile</h1>
              <h1 className="brand-name">Sadaya</h1>
              <h2 className="brand-name2">Lebih mudah,Lebih dekat</h2>
              <div className="con-wrapper">
                <h5 className="brand-con">
                  Aplikasi TopUp Pulsa Reguler dan Pembayaran Tagihan PPOB
                </h5>
              </div>
            </div>
            <div className="kiri-wrapper">
              <div className="btn-btn">
                <a
                  href="http://sadaya.otoreport.com/formagen.php?id=4884b0afa4597c5306c2766fcae31fd8c1dced1c023b62b6664a50d3088ed7cf9ef93fe9728268c383fca6f31e97741d-9"
                  className="linkdaftar"
                >
                  <div className="btn-daftar">DAFTAR</div>
                </a>
                <a
                  className="linkdaftar2"
                  href="https://play.google.com/store/apps/details?id=com.otoreport.sadaya"
                >
                  <img className="img-ply" src={ply} alt="" />
                </a>
              </div>
            </div>
          </div>
          <Tilty
            className="kanan"
            reverse
            scale={0.9}
            perspective={800}
            reset={true}
          >
            <img className="hero-hp" src={hp2} alt="Logo HP" />
          </Tilty>
          <div className='footer'>
            <img className='img-footer' src={imgFooter} alt='' />
            <div className='sosial'>
              <a className="linkdaftar" href="https://wa.me/+62895627535533">
                <img className="btn_btn" src={img_wa} alt="" />
              </a>
              <a className="linkdaftar" href="https://www.facebook.com/profile.php?id=61552927221388">
                <img className="btn_btn" src={img_fb} alt="" />
              </a>
              <a className="linkdaftar" href="https://shopee.co.id/sadayamobile">
                <img className="btn_btn" src={img_shop} alt="" />
              </a>
              <a className="linkdaftar" href="https://t.me/CS_SADAYA">
                <img className="btn_btn" src={img_tel} alt="" />
              </a>
              <a className="linkdaftar" href="https://www.instagram.com/sadayamobile">
                <img className="btn_btn" src={img_ig} alt="" />
              </a>
              <a className="linkdaftar" href="https://www.instagram.com/sadayamobile">
                <img className="btn_btn" src={img_tik} alt="" />
              </a>
            </div>
          </div>
        </div>
          {/* <div className="sosmed">
            <a className="linkdaftar" href="https://wa.me/+62895627535533">
              <img className="btn-sosmed" src={wa} alt="" />
              <h5 className="contact">0895627535533</h5>
            </a>
            <a className="linkdaftar" href="https://t.me/CS_SADAYA">
              <img className="btn-sosmed" src={tel} alt="" />
              <h5 className="contact">@CS_SADAYA</h5>
            </a>
          </div> */}
      </div>
    </div>
  );
}
export default Main;
