import "./Styles.css";
import React  from 'react';


function Admin() {
    return (
        <div className="body">
            <div className="container">
                <div className="navbar">

                </div>
                <div className="content">
                    <h1 className="Heading">Admin Page</h1>
                </div>
            </div>

        </div>
    )
    
}
export default Admin;